import React from 'react';

import { textContent, paperCategory, newsCategory, experienceCategory, dataArtAwardCategory } from '../../../types/data.global';
import award from '../../../assets/images/4-contacts/nu/award.png';


import MyPanel from '../../MyPanel';

import { Row, Col, Tabs } from 'antd';
import { TabsProps } from 'antd';
import { Image } from 'antd';
import {
  FilePdfFilled,
  FilePptFilled,
  FileTextFilled,
  VideoCameraFilled,
  LayoutFilled,
  MenuOutlined,
} from '@ant-design/icons';

import _ from 'lodash';


interface ResearchAndNewsSectionProps {
}

const ResearchAndNewsSection: React.FC<ResearchAndNewsSectionProps> = (props) => {

    // 2. Research Page
    const onChange = (key: string) => {
      console.log(key)
    }
    const researchTabElementList: TabsProps['items'] = []
    _.forEach(paperCategory, (category) => {
      // console.log(category)
      const paperList = textContent.researchPage.filter((paperItem) => paperItem.category.includes(category))
      const paperElementList = paperList.map((paperInfo, textIdx: number) => {
        const authorElementList: JSX.Element[] = []
        _.forEach(paperInfo.authorList, (author, idx: number) => {
          if (author === 'Yifang Wang' || author === 'Yifang Wang*') {
            authorElementList.push(<span className='author-highlight' key={`${textIdx}-${idx}-${author}-highlight`} id={`${textIdx}-${idx}-${author}`}>{author}</span>)
          } else {
            authorElementList.push(<span key={`${textIdx}-${idx}-${author}-common`} id={`${textIdx}-${idx}-${author}`}>{author}</span>)
          }
          if (idx < paperInfo.authorList.length - 1) {
            authorElementList.push(<span key={`${textIdx}-${idx}-${author}-comma`} id={`${textIdx}-${idx}-${author}`}>, </span>)
          }
        })
        // const authorElementList: JSX.Element[] = []
        // _.forEach(paperInfo.authorList, (author, idx: number) => {
        //   if (author === 'Yifang Wang') {
        //     authorElementList.push(<span className='author-highlight'>{author}</span>)
        //   } else {
        //     authorElementList.push(<span>{author}</span>)
        //   }
        //   if (idx < paperInfo.authorList.length - 1) {
        //     authorElementList.push(<span>, </span>)
        //   }
        // })
        return (
          <div className='paper-unit-div' key={paperInfo.title}>
            <div className='paper-teaser'>
              <div className='img-div'>
                {/* <img src={paperInfo.teaser} alt={paperInfo.venueShort} /> */}
                <div className='img-div-wrapper'>
                      <Image
                        src={paperInfo.teaser}
                        alt={paperInfo.venueShort}
                        placeholder={
                          <Image
                            preview={false}
                            src={paperInfo.teaser}
                          />
                        }
                      />
                </div>
              </div>
            </div>
            <div className='text-info'>
              <div className='title'>{paperInfo.title}</div>
              <div className='author'>{authorElementList}</div>
              <div className='venue'>
                <span className='venue-short'>{paperInfo.venueShort}</span> ({paperInfo.venue})
              </div>
              { (paperInfo.award !== '') && 
                <div className='paper-award-div'>
                  <div className='wrapper'>
                    <div className='icon'><img src={award} alt={'logo'}/></div> 
                    <div className='text'>{paperInfo.award}</div>
                  </div>
                </div> 
              }
              <div className='supp'>[{paperInfo.suppMaterialList.map((suppItem, idx) => {
                let hrefElement = <></>
                if (idx === 0) {
                  if (paperInfo.suppMaterialList.length > 1) {
                    hrefElement = <a key={idx} href={suppItem.fileLink}>{suppItem.name} |</a>
                  } else {
                    hrefElement = <a key={idx} href={suppItem.fileLink}>{suppItem.name}</a>
                  }
                }
                else if (idx < paperInfo.suppMaterialList.length - 1) {
                  hrefElement = <a key={idx} href={suppItem.fileLink}> {suppItem.name} |</a>
                } else {
                  hrefElement = <a key={idx} href={suppItem.fileLink}> {suppItem.name}</a>
                }
  
                let iconElement = <></>
                if (suppItem.name === 'PDF') {
                  iconElement = <FilePdfFilled />
                  // iconElement = <img src={pdfIconUrl} alt='pdf-icon'/>
                } else if (suppItem.name === 'Demo Video' || suppItem.name === 'Background Video' || suppItem.name === 'Case Video' || suppItem.name.includes('Talk')) {
                  iconElement = <VideoCameraFilled />
                  // iconElement = <img src={videoIconUrl} alt='video-icon'/>
                } else if (suppItem.name.includes('Slides')) {
                  iconElement = <FilePptFilled />
                  // iconElement = <img src={pptIconUrl} alt='ppt-icon'/>
                } else if (suppItem.name === 'Project Page' || suppItem.name === 'Official Website') {
                  iconElement = <LayoutFilled />
                  // iconElement = <img src={websiteIconUrl} alt='website-icon'/>
                } else if (suppItem.name === 'Supp Material' || suppItem.name === 'ICSSI2023 Poster') {
                  iconElement = <FileTextFilled />
                  // iconElement = <img src={websiteIconUrl} alt='website-icon'/>
                }
  
                return (<div className='supp-unit-div'>
                  {iconElement}
                  {hrefElement}
                </div>)
              })}]</div>
            </div>
          </div>
        )
      })
      researchTabElementList.push({
        key: category,
        label: category,
        children: paperElementList,
      })
    })
  
    // 3. News Page
    const newsTabElementList: TabsProps['items'] = []
    _.forEach(newsCategory, (category, index) => {
      const newsList = textContent.newsPage.filter((newsItem) => newsItem.category.includes(category))
      const newsElementList = newsList.map(newsInfo => {
        let linkElement = <></>
        if (newsInfo.link) {
          if (newsInfo.link.length > 1) {
            const linkList: JSX.Element[] = []
            _.forEach(newsInfo.link, (item) => linkList.push(<a href={item}>[Link]</a> ))
            linkElement = <div className='content'>
              {newsInfo.content}&nbsp;
              {newsInfo.location}&nbsp;
              {linkList}
            </div>
          } else {
            linkElement = <div className='content'>
              {newsInfo.content}&nbsp;@{newsInfo.location}. <a href={newsInfo.link[0]}>[Link]</a>
            </div>
          }
        } else {
          linkElement = <div className='content'>
            {newsInfo.content}&nbsp;
            {newsInfo.location}&nbsp;
          </div>
        }
        return (
          <div className='news-unit-div' key={index}>
            <div className='date'>{newsInfo.date}</div>
            {newsInfo.img && 
              <div className='news-teaser'>
                { (newsInfo.img).map(imgUrl => 
                    // <img src={imgUrl} alt='' />
                    <div className='img-div'>
                      <Image
                        src={imgUrl}
                        placeholder={
                          <Image
                            preview={false}
                            src={imgUrl}
                          />
                        }
                      />
                    </div>
                  )}
              </div>}
            {linkElement}
          </div>
        )
      })
      newsTabElementList.push({
        key: category,
        label: category,
        children: newsElementList,
      })
    })

  return (
    <div className='page-div' style={{width: '100%'}}>
      <Row justify="space-between" style={{width: '100%'}} className='page-div'>
      <Col xs={24} sm={24} md={15} lg={15} className='left-div'>
        <MyPanel viewTitle="RESEARCH" width={''} height={'auto'} panelHeaderHeight={72}>
          <div style={{padding: 16}} id='researchPage'>
            <Tabs defaultActiveKey={paperCategory.ALL} items={researchTabElementList} onChange={onChange} />
          </div>
        </MyPanel>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} className='left-div'>
        <MyPanel viewTitle="NEWS" width={''} height={'auto'} panelHeaderHeight={72}>
          <div style={{padding: 16}} id='newsPage' className='news-div'>
            <Tabs defaultActiveKey={newsCategory.IN2024} items={newsTabElementList} onChange={onChange} />
          </div>
        </MyPanel>
      </Col>
    </Row>
  </div>
)};

export default ResearchAndNewsSection;