import React from 'react';
import MyPanel from '../MyPanel';
import { CaretRightOutlined } from '@ant-design/icons';

import { textContent, paperCategory, newsCategory, experienceCategory, dataArtAwardCategory } from '../../types/data.global';

import { Image } from 'antd';
import _ from 'lodash';

import RadioShow1 from './【在薄情的世界里深情地活着】当与君相见.mp3';
import RadioShow2 from './【阿桑】野百合也有春天.mp3';
import RadioShow3 from './【繁花不惊，银碗盛雪】暗.mp3';

const LifePage: React.FC = () => {
	// 6. life
	function importAll(r: any) {
	return r.keys().map(r);
	}
	let hangzhoPhotoPathList: string[] = importAll(require.context('../../assets/images/5-life/1-suhang/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
	const hangzhoPhotoPreviewPathList: string[] = importAll(require.context('../../assets/images/5-life/0-preview/1-suhang/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
	hangzhoPhotoPathList = _.sortBy(hangzhoPhotoPathList, itemName => itemName)
	const hangzhouPhotoList = hangzhoPhotoPreviewPathList.map((previewPath, photoIdx) => {
	return (
	  <div className='photo-unit-div' key={previewPath}>
		<Image
		  src={hangzhoPhotoPreviewPathList[photoIdx]} // Default show preview image
		  preview={{
			src: hangzhoPhotoPathList[photoIdx] // Show original image when clicked
		  }}
		  width={'100%'}
		/>
	  </div>
	)
	})
	let tripPhotoPathList: string[] = importAll(require.context('../../assets/images/5-life/2-others/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
	let tripPhotoPreviewPathList: string[] = importAll(require.context('../../assets/images/5-life/0-preview/2-others/', false, /\.(png|jpe?g|svg|JPG|png|PNG)$/))
	tripPhotoPathList = tripPhotoPathList.sort().reverse()// _.sortBy(tripPhotoPathList, itemName => itemName)
	tripPhotoPreviewPathList = tripPhotoPreviewPathList.sort().reverse()
	const tripPhotoList = tripPhotoPreviewPathList.map((previewPath, photoIdx) => {
	return (
	  <div className='photo-unit-div' key={previewPath}>
		<Image
		  src={tripPhotoPreviewPathList[photoIdx]}
		  preview={{
			src: tripPhotoPathList[photoIdx] // Show original image when clicked
		  }}
		/>
	  </div>
	)
	})
	let academicPhotoPathList: string[] = importAll(require.context('../../assets/images/5-life/3-academic-family/', false, /\.(png|jpe?g|jpg|svg|JPG|png|PNG)$/))
	let academicPhotoPreviewPathList: string[] = importAll(require.context('../../assets/images/5-life/0-preview/3-academic-family/', false, /\.(png|jpe?g|jpg|svg|JPG|png|PNG)$/))
	academicPhotoPathList = academicPhotoPathList.sort().reverse()
	academicPhotoPreviewPathList = academicPhotoPreviewPathList.sort().reverse()
	const academicPhotoList = academicPhotoPreviewPathList.map((previewPath, photoIdx) => {
	return (
	  <div className='photo-unit-div' key={previewPath}>
		<Image
		  src={academicPhotoPreviewPathList[photoIdx]}
		  preview={{
			src: academicPhotoPathList[photoIdx] // Show original image when clicked
		  }}
		/>
	  </div>
	)
	})

	// New section for Daily Life in Evanston
	let evanstonPhotoPathList: string[] = importAll(require.context('../../assets/images/5-life/5-daily-life/', false, /\.(png|jpe?g|jpg|svg|JPG|png|PNG)$/))
	let evanstonPhotoPreviewPathList: string[] = importAll(require.context('../../assets/images/5-life/0-preview/5-daily-life/', false, /\.(png|jpe?g|jpg|svg|JPG|png|PNG)$/))
	evanstonPhotoPathList = evanstonPhotoPathList.sort().reverse()
	evanstonPhotoPreviewPathList = evanstonPhotoPreviewPathList.sort().reverse()
	const evanstonPhotoList = evanstonPhotoPreviewPathList.map((previewPath, photoIdx) => {
	return (
	  <div className='photo-unit-div' key={previewPath}>
		<Image
		  src={evanstonPhotoPreviewPathList[photoIdx]}
		  preview={{
			src: evanstonPhotoPathList[photoIdx] // Show original image when clicked
		  }}
		/>
	  </div>
	)
	})

	// Music section with YouTube links
	const musicLinks = [
		"https://www.youtube.com/watch?v=hkT2pYdimPQ&list=PLPvA04rAJ83mw84oCIcVBrzZFBf912PZv",
		
	];
	const vlogLinks = [
		"https://www.youtube.com/watch?v=JOLXq997Hr4&list=PLPvA04rAJ83lr3p24mOcaGtbLe0-qVJmb",
	];

	const radioLinks = [
		"https://youtube.com/playlist?list=PLPvA04rAJ83lRQaowYnDHQXqSklGH3hcF&si=qMfdlD9AwrKD81lx"
	]

	return (
	  	<div className='life-div' id='lifePage'>
	    	<MyPanel viewTitle="Life" width={'100%'} height={'auto'} panelHeaderHeight={72}>
				<div style={{paddingLeft: 13}}>
					I love to watch details in life and capture the small but beautiful moments via photos and videos. 
				</div>
			  	<div className='life-patent-div'>
			      <div className='gallery'>
			        <div className='photo-list'>
			          {hangzhouPhotoList}
			        </div>
			        <div className='intro'>
			          <p><CaretRightOutlined rotate={-90} /> {textContent.lifePage.hangzhou}</p>
			        </div>
			      </div>
			      <div className='gallery'>
			        <div className='photo-list'>
			          {tripPhotoList}
			        </div>
			        <div className='intro'>
			          <p><CaretRightOutlined rotate={-90} /> {textContent.lifePage.usa}</p>
			        </div>
			      </div>
			      <div className='gallery'>
			        <div className='photo-list'>
			          {academicPhotoList}
			        </div>
			        <div className='intro'>
			          <p><CaretRightOutlined rotate={-90} /> {textContent.lifePage.academicFamily}</p>
			        </div>
			      </div>
			      <div className='gallery'>
			        <div className='photo-list'>
			          {evanstonPhotoList}
			        </div>
			        <div className='intro'>
			          <p><CaretRightOutlined rotate={-90} />Life in Evanston: sunsets, sunrises, tea's quiet warmth, flowers now and then, and a touch of cooking.</p>
			        </div>
			      </div>
			    </div>
		    </MyPanel>
			<MyPanel viewTitle="Vlog" width={'100%'} height={'auto'} panelHeaderHeight={72}>
				<div style={{paddingLeft: 13, marginTop: 18}}>
					{vlogLinks.map((link, index) => (
			            <div key={index} style={{ width: '100%' }}>
			              <iframe
			                width="100%"
			                height="615"
			                src={`https://www.youtube.com/embed/videoseries?list=${link.split('list=')[1]}`}
			                title={`YouTube Vlog playlist ${index + 1}`}
			                frameBorder="0"
			                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			                allowFullScreen
			              ></iframe>
			            </div>
			        ))}
				</div>
			</MyPanel>
			<MyPanel viewTitle="Music" width={'100%'} height={'auto'} panelHeaderHeight={72}>
				<div style={{paddingLeft: 13,lineHeight: '1.6'}}>
					I love to sing, play the guitar and have recently started learning piano. Perhaps it's because of my nine years in a choir, but singing indeed brings me a sense of ease.
				</div>
				<div style={{paddingLeft: 13, marginTop: 18}}>
					{musicLinks.map((link, index) => (
			            <div key={index} style={{ width: '100%' }}>
			              <iframe
			                width="100%"
			                height="615"
			                src={`https://www.youtube.com/embed/videoseries?list=${link.split('list=')[1]}`}
			                title={`YouTube Music playlist ${index + 1}`}
			                frameBorder="0"
			                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			                allowFullScreen
			              ></iframe>
			            </div>
			        ))}
				</div>
			</MyPanel>
			<MyPanel viewTitle="Radio" width={'100%'} height={'auto'} panelHeaderHeight={72}>
				<div style={{paddingLeft: 13,lineHeight: '1.6'}}>
					At a younger age, I was a radio host. 
				</div>
				<div style={{paddingLeft: 13, marginTop: 18}}>
					{/* how to read local mp3 file list and play with a 播放器 */} 
					{/* <div style={{marginBottom: '32px'}}>
						<p style={{marginBottom: '12px'}}>【在薄情的世界里深情地活着】当与君相见</p>
						<audio controls>
							<source src={RadioShow1} type="audio/mp3" />
						</audio>
					</div>
					<div style={{marginBottom: '32px'}}>
						<p style={{marginBottom: '12px'}}>【阿桑】野百合也有春天</p>
						<audio controls>
							<source src={RadioShow2} type="audio/mp3" />
						</audio>
					</div>
					<div style={{marginBottom: '32px'}}>
						<p style={{marginBottom: '12px'}}>【繁花不惊，银碗盛雪】暗</p>
						<audio controls>
							<source src={RadioShow3} type="audio/mp3" />
						</audio>
					</div> */}
					{radioLinks.map((link, index) => (
			            <div key={index} style={{ width: '100%' }}>
			              <iframe
			                width="100%"
			                height="615"
			                src={`https://www.youtube.com/embed/videoseries?list=${link.split('list=')[1]}&enablejsapi=1`}
			                title={`YouTube Music playlist ${index + 1}`}
			                frameBorder="0"
			                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			                allowFullScreen
			              ></iframe>
			            </div>
			        ))}
				</div>
			</MyPanel>
		                
	    </div>
  	)
};

export default LifePage;