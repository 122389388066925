import React from 'react';
import './index.scss';

import MyPanel from '../../MyPanel';


import { CaretRightOutlined } from '@ant-design/icons';

import { textContent, paperCategory, newsCategory, experienceCategory, dataArtAwardCategory } from '../../../types/data.global';

import { Image } from 'antd';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import photography from './photography.jpg';
import guitar from './guitar.jpg'; 
import radio from './radio.jpg';
import vlog from './vlog.jpg';


const Card = ({ 
	number, 
	title, 
	subtitle, 
	children, 
	className = '' 
  }: { number: string, title: string, subtitle: string, children: React.ReactNode, className?: string }) => {
	return (
	  <div className={`relative w-full h-full p-6 transition-transform hover:-translate-y-1 ${className}`}>
		<div className="absolute top-3 right-3 text-[10px] opacity-50">
		  {number}/06
		</div>
		<div className="mb-4">
		  <h2 className="text-[10px] tracking-[0.2em] font-medium uppercase">{title}</h2>
		  <h3 className="text-[10px] tracking-[0.2em] font-normal uppercase">{subtitle}</h3>
		</div>
		{children}
	  </div>
	);
  };
  const KenshoCard = () => (
	<Card
	  number="01"
	  title="FURNITURE"
	  subtitle="DESIGN"
	  className="bg-gray-50"
	>
	  <div className="absolute bottom-6 left-6">
		<div className="flex flex-col text-red-900 font-serif text-base">
		  {['k', 'e', 'n', 's', 'h', 'ō'].map((char, i) => (
			<span key={i} className="leading-tight">{char}</span>
		  ))}
		</div>
	  </div>
	</Card>
  );
  
  const ReifiedCard = () => (
	<Card
	  number="02"
	  title="FORM STUDY"
	  subtitle="ART DIRECTION"
	  className="bg-gray-300"
	>
	  <div className="mt-8 font-['Inter']">
		<div className="text-2xl font-light text-gray-700 mb-1">Life</div>
		<div className="text-2xl font-bold text-gray-700 mb-1">Rei</div>
		<div className="text-2xl font-bold text-gray-700">fied.</div>
	  </div>
	</Card>
  );
  
  const ZineCard = () => (
	<Card
	  number="03"
	  title="FURNITURE DESIGN /"
	  subtitle="OBJECT DESIGN"
	  className="bg-gray-50"
	>
	  <div className="mt-8 space-y-1 font-['Inter']">
		<div className="text-2xl font-light">āto</div>
		<div className="text-2xl font-light tracking-wide">ZINE</div>
		<div className="text-2xl font-light">कting</div>
	  </div>
	</Card>
  );
  
  const ConceptCard = () => (
	<Card
	  number="04"
	  title="CONCEPT ART /"
	  subtitle="VISUAL ARTS"
	  className="bg-black text-white"
	>
	  <div className="mt-8">
		<p className="text-sm mb-4">Some shapes i like.</p>
		<div className="flex items-center space-x-4 text-xl">
		  <span>○</span>
		  <span>□</span>
		  <span>△</span>
		</div>
	  </div>
	</Card>
  );
  
  const CADCard = () => (
	<Card
	  number="05"
	  title="CAD"
	  subtitle="3d modeling"
	  className="bg-black text-white"
	>
	  <div className="mt-1">
		<h3 className="text-[10px] tracking-[0.2em] font-normal uppercase">Rendering</h3>
	  </div>
	  <div className="absolute bottom-6 right-6 text-right">
		<p className="text-[10px] tracking-wide">2020</p>
		<p className="text-[10px] tracking-wide">PORTFOLIO</p>
	  </div>
	</Card>
  );

const LifePage: React.FC = () => {
	
	return (
	  	<div className='life-div-homepage' id='lifePage-homepage' style={{marginTop: '60px', marginBottom: '160px'}}>
	    	<MyPanel viewTitle="Life" width={'100%'} height={'auto'} panelHeaderHeight={72}>
			<div className="portfolio">
		        <div className="portfolio__container" style={{marginTop: '32px'}}>
					<Link to="/lifePage">
						<div className="portfolio__card-wrapper">
			                <div className="card" style={{
			                    backgroundImage: `url(${photography})`,
			                    backgroundSize: 'cover',
			                    backgroundPosition: 'center',
			                    position: 'relative', 
			                    zIndex: 1
			                }}>
			                    <div className="card--kensho" style={{
			                        position: 'absolute',
			                        top: 0,
			                        left: 0,
			                        width: '100%',
			                        height: '100%',
			                        zIndex: 0
			                    }}></div>
			                    <div className="card__number">1 / 4</div>
			                    <div className="card__header" style={{
			                        position: 'relative',
			                        zIndex: 3
			                    }}>
			                        <h2>Photography</h2>
			                        {/* <h3>Design</h3> */}
			                    </div>
			                    <div className="kensho-text">
			                        {/* <span>framing</span>
									<span>life</span>		 */}
			                        <span>m</span>
			                        <span>e</span>
			                        <span>m</span>
			                        <span>o</span>
			                        <span>r</span>
									<span>y</span>
			                    </div>
			                </div>
			            </div>
					</Link>

					<Link to="/lifePage">
						<div className="portfolio__card-wrapper">
							{/* <div className="card card--reified"> */}
							<div className="card" style={{
			                    backgroundImage: `url(${guitar})`,
			                    backgroundSize: 'cover',
			                    backgroundPosition: 'center',
			                    position: 'relative', 
			                    zIndex: 1
			                }}>
			                    <div className="card--reified" style={{
			                        position: 'absolute',
			                        top: 0,
			                        left: 0,
			                        width: '100%',
			                        height: '100%',
			                        zIndex: 0
			                    }}></div>
			                    <div className="card__number">2 / 4</div>
			                    <div className="card__header" style={{
			                        position: 'relative',
			                        zIndex: 3
			                    }}>
			                        <h2>Music</h2>
			                        {/* <h3>Art Direction</h3> */}
			                    </div>
			                    <div className="reified-text" style={{
			                        position: 'relative',
			                        zIndex: 3
			                    }}>
			                        <div className="life">Singing</div>
			                        <div className="rei">Guitar</div>
			                        <div className="fied">Piano</div>
			                    </div>
			                </div>
		            </div>
					</Link>

					<Link to="/lifePage">
						<div className="portfolio__card-wrapper">
			                {/* <div className="card card--zine"> */}
							<div className="card" style={{
			                    backgroundImage: `url(${vlog})`,
			                    backgroundSize: 'cover',
			                    backgroundPosition: 'center',
			                    position: 'relative', 
			                    zIndex: 1
			                }}>
			                    <div className="card--reified" style={{
			                        position: 'absolute',
			                        top: 0,
			                        left: 0,
			                        width: '100%',
			                        height: '100%',
			                        zIndex: 0
			                    }}></div>
			                    <div className="card__number"> 3 / 4</div>
			                    <div className="card__header" style={{
			                        position: 'relative',
			                        zIndex: 3
			                    }}>
			                        <h2>Vlog</h2>
			                        {/* <h3>Object Design</h3> */}
			                    </div>
			                    <div className="zine-text" style={{
			                        position: 'relative',
			                        zIndex: 3
			                    }}>
									<div>Capture</div>
			                        <div>Life</div>
			                        <div>Moment</div>
			                        {/* <div>āto</div>
			                        <div>ZINE</div>
			                        <div>कting</div> */}
			                    </div>
			                </div>
			            </div>
					</Link>

		            {/* <div className="portfolio__card-wrapper">
		                <div className="card card--concept">
		                    <div className="card__number">04/06</div>
		                    <div className="card__header">
		                        <h2>Concept Art /</h2>
		                        <h3>Visual Arts</h3>
		                    </div>
		                    <div className="shapes">
		                        <p className="shapes-text">Some shapes i like.</p>
		                        <div className="shapes-icons">
		                            <span>○</span>
		                            <span>□</span>
		                            <span>△</span>
		                        </div>
		                    </div>
		                </div>
		            </div> */}


					<Link to="/lifePage">
						<div className="portfolio__card-wrapper">
							{/* <div className="card card--cad"> */}
							<div className="card" style={{
			                    backgroundImage: `url(${radio})`,
			                    backgroundSize: 'cover',
			                    backgroundPosition: 'center',
			                    position: 'relative',
			                    zIndex: 1
			                }}>
								<div className="card--cad" style={{
			                        position: 'absolute',
			                        top: 0,
			                        left: 0,
			                        width: '100%',
			                        height: '100%',
			                        zIndex: 0
			                    }}></div>
			                    <div className="card__number">4 / 4</div>
			                    <div className="card__header" style={{
				                        position: 'relative',
				                        zIndex: 3
				                    }}>
			                        <h2>Radio</h2>
			                        {/* <h3>3d modeling</h3>
			                        <h3>Rendering</h3> */}
			                    </div>
			                    <div className="portfolio-text" style={{
				                        // position: 'relative',
				                        zIndex: 3
				                    }}>
			                        <p>Tune In, </p>
			                        <p>Reflect, and Resonate</p>
			                    </div>
		                </div>
						</div>
					</Link>
		        </div>
		    </div>
		    </MyPanel>
	    </div>
  	)
};

export default LifePage;