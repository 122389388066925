import React, { useState, useEffect, useRef } from 'react';

import MyPanel from '../../MyPanel';
import Artwork from '../../Artwork';

interface DataArtSectionProps {
  textContent: any;
}

const DataArtSection: React.FC<DataArtSectionProps> = (props) => {
  const { textContent } = props;

  const imageElementList: JSX.Element[] = textContent.dataArtPage.map((item: any, textIdx: number) => <Artwork key={textIdx} item={item} textIdx={textIdx} />)


  // art: 再加：点击照片以后，出现作品创作简介。有些是对社会的观察。有些是朋友猫猫的去世。中英文。
  // 4. Data Art Page (https://isamatov.com/react-grid-layout-tutorial/)
  // scroll
  const galleryRef = useRef(null)
  useEffect(() => {
    const handleScroll = (event: React.WheelEvent) => {
      if (galleryRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = galleryRef.current;
        const atEnd = scrollLeft + clientWidth >= scrollWidth;
        const atStart = scrollLeft <= 0;
  
        if (event.deltaY > 0 && !atEnd) {
          // Scrolling right
          event.preventDefault();
          // @ts-ignore
          galleryRef.current.scrollLeft += Math.abs(event.deltaY);
        } else if (event.deltaY < 0 && !atStart) {
          // Scrolling left
          event.preventDefault();
          // @ts-ignore
          galleryRef.current.scrollLeft -= Math.abs(event.deltaY);
        }
        // Allow vertical scrolling if at the start or end
      }
    };
  
    const galleryElement = galleryRef.current;
    if (galleryElement) {
      // @ts-ignore
      galleryElement.addEventListener('wheel', handleScroll, { passive: false });
    }
  
    // Cleanup the event listener on unmount
    return () => {
      if (galleryElement) {
        // @ts-ignore
        galleryElement.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  return (
    <div className='data-art-div' id='dataArtPage' style={{width: '100%'}}>
      <MyPanel viewTitle="DATA-DRIVEN ART" width={'100%'} height={'auto'} panelHeaderHeight={72}>
        <div className='queto'>
          "We have art in order not to die of the truth." &mdash; Friedrich Nietzsche
          {/* 尼采还说过，“我们拥有艺术，为的是不致于亡于真理。”在这里，“真理”一词再次用来指称超感性的所谓“真实世界”，它暗藏着毁灭生命的危险。尼采的“生命”总是指摆脱真理纠缠的那种“上升的生命”。超感性云云，不过是为了诱使生命离开健康活泼的感性和感官世界，使生命力衰弱、枯竭下去……幸亏我们还拥有艺术，才不至于被这样的超感官的真理所毁。有了艺术，超感官的东西才不能使生命衰朽到极度虚亏全面崩溃的田地。
          
          弗里德里希·尼采的名言“我们拥有艺术是为了不死于真相”概括了他关于艺术作为缓冲生活严酷现实的力量的哲学。尼采经常努力解决存在主义问题以及关于人类存在的残酷真相——例如生命缺乏内在意义、痛苦无处不在以及死亡的必然性。这些真相可能极其残酷，可能导致虚无主义或绝望。
  
          在尼采看来，艺术是一种至关重要的平衡。它允许个人表达自己、解释自己的经历，并以超越现实的方式参与生活。艺术通过为“真相”注入美感、意义和升华，改变了“真相”的严酷性。通过艺术，痛苦或深刻的真相不仅可以忍受，而且可以赋予其深度和共鸣。
  
          因此，艺术成为生存的必要行为——它使我们不被存在真理的重压所压垮。通过创造或体验艺术，人们可以找到一种与真理共存而不被真理压垮的方法。这是一种形而上的安慰，使生活更加愉快，精神更加丰富。    */}
        </div>
        <div className='data-art-parent-div' 
          ref={galleryRef} 
        >
          {/* {showScrollButtons && (
            <button className="scroll-button left" onClick={scrollLeft}>
              &#9664;
            </button>
          )} */}
          {imageElementList}
          {/* {showScrollButtons && (
            <button className="scroll-button right" onClick={scrollRight}>
              &#9654;
            </button>
          )} */}
        </div>
      </MyPanel>
    </div>
  )
};

export default DataArtSection;