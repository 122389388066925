import React, { useState, useEffect, useRef } from 'react';
import './App.scss';
// import 'antd/dist/antd.css';
// import "antd/dist/antd.min.css";
import 'antd/dist/reset.css';
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// import "@fortawesome/fontawesome-free/css/all.min.css";

import { textContent } from './types/data.global';
import _ from 'lodash';
// import styled from "styled-components";
import yinzhangUrl from './assets/images/1-about/favicon.png';
import cvUrl from './assets/images/1-about/yifang_cv_202411.pdf'

import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import { MenuProps } from 'antd';
import { Layout, Anchor, theme } from 'antd';
import { Row, Col } from 'antd';
import { Spin, Divider } from 'antd';


import {
  FilePdfFilled,
  MenuOutlined,
  CloseOutlined
} from '@ant-design/icons';

import HomePage from './components/HomePage';
import DataArtPage from './components/DataArtPage';
import LifePage from './components/LifePage';

const { Header, Content, Footer } = Layout;

function App() {

  // 7. responsive component
  const rootSubmenuKeys = ['sub1', 'sub2', 'sub4']
  const [openKeys, setOpenKeys] = useState(['sub1'])
  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  }

  type MenuItem = Required<MenuProps>['items'][number]
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }
  const items: MenuItem[] = [
    getItem('', 'sub1', <FilePdfFilled />, [
      getItem('Option 1', '1'),
      getItem('Option 2', '2'),
      getItem('Option 3', '3'),
      getItem('Option 4', '4'),
    ]),
    // getItem('Navigation Two', 'sub2', <FilePdfFilled />, [
    //   getItem('Option 5', '5'),
    //   getItem('Option 6', '6'),
    //   getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')]),
    // ]),
    // getItem('Navigation Three', 'sub4', <FilePdfFilled />, [
    //   getItem('Option 9', '9'),
    //   getItem('Option 10', '10'),
    //   getItem('Option 11', '11'),
    //   getItem('Option 12', '12'),
    // ]),
  ]


  const [isVisible, setIsVisible] = useState(false)
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  }

  // set height
  const [headerHeight, setHeaderHeight] = useState('120px'); // Initial header height
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHeaderHeight('80px'); // Height when scrolled down
      } else {
        setHeaderHeight('120px'); // Default height
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array means this effect runs once on mount and clean up on unmount


  // handle page loading and navigation
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setLoading(false);
  }, [location]);
  const handleAnchorClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    link: { href: string; title: React.ReactNode }
  ) => {
    e.preventDefault();
  
    if (link.href === cvUrl) {
      // Open the CV in a new tab
      window.open(link.href, '_blank', 'noopener,noreferrer');
      return;
    }
  
    setLoading(true);
  
    if (link.href.startsWith('/')) {
      // If navigating to a different route, use `navigate`
      navigate(link.href);
    } else if (link.href.startsWith('#')) {
      // If navigating within the current page (anchors)
      const targetId = link.href.slice(1);
      if (location.pathname !== '/') {
        // If not on the home page, navigate to the root first
        navigate('/');
        setTimeout(() => {
          const targetElement = document.getElementById(targetId);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      } else {
        // If on the same page, scroll directly
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } else {
      // External link or other link
      window.location.href = link.href;
    }
  
    setLoading(false);
  };


  // mobile mode 菜单
  const [isFolded, setIsFolded] = useState('none')
  const [menuCollapseClassname, setMenuCollapseClassname] = useState('collapse-menu-div')
  const handleFoldingNav = () => {
    if (isFolded === 'none') {
      setIsFolded('block')
      setMenuCollapseClassname('collapse-menu-div active')
    } else {
      setIsFolded('none')
      setMenuCollapseClassname('collapse-menu-div')
    }
  }
  const handleCloseNav = () => {
    if (isFolded === 'none') {
      setIsFolded('block')
      setMenuCollapseClassname('collapse-menu-div active')
    } else {
      setIsFolded('none')
      setMenuCollapseClassname('collapse-menu-div')
    }
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#4F2582', // https://ant.design/docs/react/customize-theme
          colorSplit: 'rgba(5, 5, 5, 0.06)',
          colorText: '#4e4e4e',
          colorBgLayout: location.pathname === '/dataArtPage' ? '#000' : 'white', // Set background color based on route
        },
      }}
    >
      <Layout style={{ backgroundColor: location.pathname === '/dataArtPage' ? '#000' : 'white' }}>
        <Header 
          style={{ 
            display: 'flex', 
            alignItems: 'center', 
            height: headerHeight,
            backgroundColor: location.pathname === '/dataArtPage' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.9)', 
            color: location.pathname === '/dataArtPage' ? '#666' : '#3d3d3d'
          }} 
          className='header-div'
        >
          <Row justify="space-between" style={{width: '96%'}}>
            <Col xs={20} sm={20} md={20} lg={8} className='left-div'>
              <div className='logo'><img src={yinzhangUrl} alt='logo'/></div>
              <div className='text'>YIFANG WANG</div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={16} id='right-div' className='right-div'>
              <div className='right-div-inside'>
                <Anchor
                  direction="horizontal"
                  onClick={(e: React.MouseEvent<HTMLElement>, link: { href: string; title: React.ReactNode }) => handleAnchorClick(e as React.MouseEvent<HTMLAnchorElement>, link)}
                  style={{color: 'white'}}
                  // style={{ color: location.pathname === '/dataArtPage' ? '#ffffff' : '#3d3d3d' }}
                  items={[
                    {
                      key: 'aboutPage',
                      href: '#aboutPage',
                      title: 'ABOUT',
                    },
                    {
                      key: 'researchPage',
                      href: '#researchPage',
                      title: 'RESEARCH',
                    },
                    {
                      key: 'newsPage',
                      href: '#newsPage',
                      title: 'NEWS',
                    },
                    {
                      key: 'dataArtPage',
                      href: '/dataArtPage',
                      title: <Link to="/dataArtPage" target="_blank">
                                DATA-DRIVEN ART
                             </Link>,
                      // href: '#dataArtPage',
                      // title: 'DATA-DRIVEN ART',
                    },
                    {
                      key: 'experiencePage',
                      href: '#experiencePage',
                      title: 'EXPERIENCE',
                    },
                    {
                      key: 'contactPage',
                      href: '#contactPage',
                      title: 'CONTACTS',
                    },
                    {
                      key: 'lifePage',
                      href: '/lifePage',
                      title: <Link to="/lifePage">
                                LIFE
                             </Link>,
                      // title: 'LIFE',
                      // target: '_blank',
                    },
                    {
                      key: 'CV',
                      href: cvUrl,
                      title: 'CV',
                    },
                  ]}
                />
              </div>
            </Col>
            <Col xs={4} sm={4} md={4} lg={0} id='right-div-collapse' className='right-div-collapse' onClick={toggleVisibility}>
              <div className='menu'>
                <MenuOutlined style={{ color: location.pathname === '/dataArtPage' ? '#fff' : '#3b3b3b' }} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} className={`right-div-collapse ${isVisible ? 'visible' : 'hidden'}`}>
              <div className={`collapse-menu-div`}>
                <Divider className='collapse-menu-div-unit' plain>
                  <Link to="/#aboutPage" onClick={(e) => handleAnchorClick(e, { href: '#aboutPage', title: 'ABOUT' })} style={{ color: location.pathname === '/dataArtPage' ? '#fff' : '#3b3b3b' }}>ABOUT</Link>
                </Divider>
                <Divider className='collapse-menu-div-unit' plain>
                  <Link to="/#researchPage" onClick={(e) => handleAnchorClick(e, { href: '#researchPage', title: 'RESEARCH' })} style={{ color: location.pathname === '/dataArtPage' ? '#fff' : '#3b3b3b' }}>RESEARCH</Link>
                </Divider>
                <Divider className='collapse-menu-div-unit' plain>
                  <Link to="/#newsPage" onClick={(e) => handleAnchorClick(e, { href: '#newsPage', title: 'NEWS' })} style={{ color: location.pathname === '/dataArtPage' ? '#fff' : '#3b3b3b' }}>NEWS</Link>
                </Divider>
                <Divider className='collapse-menu-div-unit' plain>
                  <Link to="/dataArtPage" target="_blank" style={{ color: location.pathname === '/dataArtPage' ? '#fff' : '#3b3b3b' }}>DATA-DRIVEN ART</Link>
                </Divider>
                <Divider className='collapse-menu-div-unit' plain>
                  <Link to="/#experiencePage" onClick={(e) => handleAnchorClick(e, { href: '#experiencePage', title: 'EXPERIENCE' })} style={{ color: location.pathname === '/dataArtPage' ? '#fff' : '#3b3b3b' }}>EXPERIENCE</Link>
                </Divider>
                <Divider className='collapse-menu-div-unit' plain>
                  <Link to="/#contactPage" onClick={(e) => handleAnchorClick(e, { href: '#contactPage', title: 'CONTACTS' })} style={{ color: location.pathname === '/dataArtPage' ? '#fff' : '#3b3b3b' }}>CONTACTS</Link>
                </Divider>
                <Divider className='collapse-menu-div-unit' plain>
                  <Link to="/lifePage" target="_blank" style={{ color: location.pathname === '/dataArtPage' ? '#fff' : '#3b3b3b' }}>LIFE</Link>
                </Divider>
                <Divider className='collapse-menu-div-unit' plain>
                  <a href={cvUrl} target="_blank" style={{ color: location.pathname === '/dataArtPage' ? '#fff' : '#3b3b3b' }}>CV</a>
                </Divider>
              </div>
              

              {/* <div className={menuCollapseClassname}>
                <div className='close-div collapse-menu-div-unit'>
                  <div className='menu' onClick={handleCloseNav}>
                    <CloseOutlined />
                  </div>
                </div>
                <Divider className='collapse-menu-div-unit' plain><a href="#backgroundPage">BACKGROUND</a></Divider>
                <Divider className='collapse-menu-div-unit' plain><a href="#innovationinsightsPage">INNOVATIONINSIGHTS</a></Divider>
                <Divider className='collapse-menu-div-unit' plain><a href="#frameworkPage">FRAMEWORK</a></Divider>
                <Divider className='collapse-menu-div-unit' plain><a href="#examplePage">EXAMPLE</a></Divider>
                <Divider className='collapse-menu-div-unit' plain><a href="#aboutPage">TEAM</a></Divider>
                <Divider className='collapse-menu-div-unit' plain><a href="#contactPage">MATERIAL</a></Divider>
              </div> */}
            </Col>
          </Row>
        </Header>
        <Content >
        {/* style={{ padding: '72px 88px' }}  */}
          {loading ? (
            <Spin
              tip="Loading..."
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}
            />
          ) : (
            <Routes>
              <Route
                path="/"
                element={
                  // Your home page content including the anchor navigation
                  <div className='research-page'>
                    <div className='content-div'>
                      <HomePage textContent={textContent} />
                    </div>
                  </div>
                }
              />
              <Route path="/lifePage" element={
                <div className='research-page'>
                  <div className='content-div'>
                    <LifePage />
                  </div>
                </div>
              } />
              <Route path="/dataArtPage" element={
                <div className='data-art-page'>
                  <div className='content-div'>
                    <DataArtPage />
                  </div>
                </div>
              } />
            </Routes>
          )}
          {/* <Routes>
            <Route path="/" element={
              // Your home page content including the anchor navigation
              <HomePage textContent={textContent} />
            } />
            <Route path="/lifePage" element={<LifePage />} />
          </Routes> */}
        </Content>
        <Footer 
          style={{ 
            textAlign: 'center', 
            backgroundColor: window.location.pathname === '/dataArtPage' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(228, 224, 238, 1)',
            color: window.location.pathname === '/dataArtPage' ? '#fff' : '#3b3b3b'
          }} 
          className='footer'
        >
          <div className='footer-div'>
            <div className='left'>
              以一灯传诸灯 终至万灯皆明
              {/* 每个人都有属于自己的一片森林，也许我们从来不曾去过，但它一直在那里，总会在那里， */}
              {/* 迷失的人迷失了 相逢的人会再相逢 */}
            </div>
            <div className='center'>
              Copyright ©  2017- 2024  Yifang Wang
            </div>
            <div className='right'>
              To dream the impossible dream.
              {/* 以一灯传诸灯 终至万灯皆明 */}
            </div>
          </div>
        </Footer>
      </Layout>
    </ConfigProvider>
  )
}


export default App;
