import { FunctionComponent, useState, useEffect } from 'react';
import './index.scss';

import _ from 'lodash';
import { Modal, Button } from 'antd';
import { Row, Col, Card, Image } from 'antd';
import {
  FilePdfFilled,
  FilePptFilled,
  FileTextFilled,
  VideoCameraFilled,
  LayoutFilled,
  CloseOutlined, 
} from '@ant-design/icons';

import { dataArtAwardCategory } from '../../types/data.global';
import IIB2022Logo from '../../assets/images/3-data-art/IIB2022.png';
import IIB2023Logo from '../../assets/images/3-data-art/IIB2023.png';
import InfoPlusLogo from '../../assets/images/3-data-art/info+2023.png';
import ISEA2024Logo  from '../../assets/images/3-data-art/ISEA2024.png';
import SiggraphArtLogo from '../../assets/images/3-data-art/siggraph-art-logo.jpg';
import visap2021Logo from '../../assets/images/3-data-art/visap2021-2.png';
import visap2023Logo from '../../assets/images/3-data-art/visap2023.jpg';
import chinavisap2024 from '../../assets/images/3-data-art/chinavisap2024.jpg';
import LumenPrize2024 from '../../assets/images/3-data-art/lumen-prize-2024.png';
import ElectricDreams2022 from '../../assets/images/3-data-art/electric-dreams-2022.png';

interface ArtworkProps {
  item: any;
  textIdx: number;
}

const Artwork: FunctionComponent<ArtworkProps> = (props) => {
  const { item, textIdx } = props

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (event: any) => {
    event.stopPropagation(); // Prevent click event from propagating to parent elements
    setIsModalOpen(true);
  };
  const handleCancel = (event: any) => {
    event.stopPropagation();
    setIsModalOpen(false);
  };

  const authorElementList: JSX.Element[] = []
  _.forEach(item.authorList, (author, idx: number) => {
    if (author === 'Yifang Wang') {
      authorElementList.push(<span className='author-highlight' key={`${textIdx}-${idx}-${author}-highlight`} id={`${textIdx}-${idx}-${author}`}>{author}</span>)
    } else {
      authorElementList.push(<span key={`${textIdx}-${idx}-${author}-common`} id={`${textIdx}-${idx}-${author}`}>{author}</span>)
    }
    if (idx < item.authorList.length - 1) {
      authorElementList.push(<span key={`${textIdx}-${idx}-${author}-comma`} id={`${textIdx}-${idx}-${author}`}>, </span>)
    }
  })

  // find video
  const videoItem = item.suppMaterialList.find((item: any) => item.name === 'Demo Video')
 
  return (
    <div key={item.title} className='grid-unit' onClick={showModal}>
      <div className='artwork-teaser'>
        <div className='front-div'>
          { 
            (item.award.length > 0) ? item.award.map(((award: any) => {
              if (award === dataArtAwardCategory.IIB2022) {
                return <div className='artwork-award'><img src={IIB2022Logo} alt={dataArtAwardCategory.IIB2022} /></div>
              } else if (award === dataArtAwardCategory.IIB2023) {
                return <div className='artwork-award'><img src={IIB2023Logo} alt={dataArtAwardCategory.IIB2023} /></div>
              } else if (award === dataArtAwardCategory.INFOPLUS) {
                return <div className='artwork-award'><img src={InfoPlusLogo} alt={dataArtAwardCategory.INFOPLUS} /></div>
              } else if (award === dataArtAwardCategory.ISEA2024) {
                return <div className='artwork-award'><img src={ISEA2024Logo} alt={dataArtAwardCategory.ISEA2024} /></div>
              } else if (award === dataArtAwardCategory.SIGGRAPHART) {
                return <div className='artwork-award'><img src={SiggraphArtLogo} alt={dataArtAwardCategory.SIGGRAPHART} /></div>
              } else if (award === dataArtAwardCategory.VISAP2021) {
                return <div className='artwork-award'><img src={visap2021Logo} alt={dataArtAwardCategory.VISAP2021} /></div>
              } else if (award === dataArtAwardCategory.VISAP2023) {
                return <div className='artwork-award'><img src={visap2023Logo} alt={dataArtAwardCategory.VISAP2023} /></div>
              } else if (award === dataArtAwardCategory.CHINAVISAP2024) {
                return <div className='artwork-award'><img src={chinavisap2024} alt={dataArtAwardCategory.CHINAVISAP2024} /></div>
              } else if (award === dataArtAwardCategory.LumenPrize2024) {
                return <div className='artwork-award'><img src={LumenPrize2024} alt={dataArtAwardCategory.LumenPrize2024} /></div>
              } else if (award === dataArtAwardCategory.ElectricDreams2022) {
                return <div className='artwork-award'><img src={ElectricDreams2022} alt={dataArtAwardCategory.ElectricDreams2022} /></div>
              } 
              else {
                return <></>
              }
            })) : <></>
          }
        </div>
        <div className='back-div'>
          <img className='artwork-image' src={item.teaser} alt={item.title} />
        </div>
      </div>
      <div className="photo-info">
        <div className="left">
          <div className='order'>/ {textIdx > 9 ? (textIdx + 1) : `0${textIdx + 1}`}</div>
        </div>
        <div className="right">
          <div className="title">{item.titleShort}</div>
          <div className="year">{item.year}</div>
        </div>
      </div>

      <Modal
        key={item.title}
        className="my-modal"
        // title={item.titleShort}
        open={isModalOpen}
        onCancel={handleCancel}
        closeIcon={<CloseOutlined onClick={handleCancel} />}
        footer={null}  // Remove default footer buttons
        width={'80%'}
        centered
        // style={{ top: 20 }}  // Adjust top margin if needed
      >
        <div className='section' style={{margin: '0 0'}}>
          {
            (videoItem) && 
            <video controls autoPlay={true} muted style={{width: '100%', height: '420px', objectFit: 'cover'}}>
              <source src={videoItem.fileLink} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          }
        </div>
        {/* <div className='section' style={{margin: '0 0', position: 'relative'}}>
          {
            (videoItem) && 
            <video controls autoPlay={true} muted style={{position: 'absolute', width: '100%', height: '100%', objectFit: 'cover'}}>
              <source src={videoItem.fileLink} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          }
          <div className='artwork-title' style={{position: 'relative', zIndex: 1}}>
            <div>{item.title}</div>
          </div>
        </div> */}
        <div className='section'>
          <div className='section-title'>Publication</div>
          <div className='title'>{item.title}</div>
          <div className='author'>{authorElementList}</div>
          <div className='supp'>
            [{item.suppMaterialList.map((suppItem: any, idx: number) => {
                let hrefElement = <></>
                if (idx === 0) {
                  if (item.suppMaterialList.length > 1) {
                    hrefElement = <a href={suppItem.fileLink}>{suppItem.name} |</a>
                  } else {
                    hrefElement = <a href={suppItem.fileLink}>{suppItem.name}</a>
                  }
                }
                else if (idx < item.suppMaterialList.length - 1) {
                  hrefElement = <a href={suppItem.fileLink}> {suppItem.name} |</a>
                } else {
                  hrefElement = <a href={suppItem.fileLink}> {suppItem.name}</a>
                }

                let iconElement = <></>
                if (suppItem.name === 'PDF') {
                  iconElement = <FilePdfFilled />
                  // iconElement = <img src={pdfIconUrl} alt='pdf-icon'/>
                } else if (suppItem.name === 'Demo Video' || suppItem.name === 'Background Video' || suppItem.name === 'Case Video') {
                  iconElement = <VideoCameraFilled />
                  // iconElement = <img src={videoIconUrl} alt='video-icon'/>
                } else if (suppItem.name === 'Slides') {
                  iconElement = <FilePptFilled />
                  // iconElement = <img src={pptIconUrl} alt='ppt-icon'/>
                } else if (suppItem.name === 'Project Page' || suppItem.name === 'Official Website') {
                  iconElement = <LayoutFilled />
                  // iconElement = <img src={websiteIconUrl} alt='website-icon'/>
                } else if (suppItem.name === 'Supp Material' || suppItem.name === 'ICSSI2023 Poster') {
                  iconElement = <FileTextFilled />
                  // iconElement = <img src={websiteIconUrl} alt='website-icon'/>
                }

                return (<div className='supp-unit-div'>
                  {iconElement}
                  {hrefElement}
                </div>)
              })}]
          </div>
        </div>
        {
          (item.award.length > 0) && 
          <div className='section'>
            <div className='section-title'>Exhibition & Prize</div>
            <div className='award' style={{marginTop: 8}}>
              { (item.award.map((item: string) => 
                <div className='content'>
                  {item}
                </div>
              ))}
            </div>
          </div>
        }
        { (item.mediaCoverage.length > 0) && 
          <div className='section'>
            <div className='section-title'>Media Coverage</div>
            { (item.mediaCoverage.map((item: { name: string, title: string, fileLink: string }) => 
            <div className='media-unit'>
                <div className='media-unit-section media-title'>
                  <div className='field-wrapper'>
                    <div className='field'>Title</div>
                  </div>
                  <div className='content'><a href={item.fileLink}>{item.title}</a></div>
                </div>
                <div className='media-unit-section media-name'>
                  <div className='field-wrapper'>
                    <div className='field'>Media</div>
                  </div>
                  <div className='content'>{item.name}</div>
                </div>
            </div>))}
          </div>
        }
        {
          (item.imageList.length > 0) && 
          <div className='section'>
            <div className='section-title'>Gallery</div>
            <div className='gallery' style={{marginTop: 8}}>
              {item.imageList.map((image: string, index: number) => { 
                const fileExtension = image.slice(image.length - 3, image.length).toLowerCase();
                if (!['mp4'].includes(fileExtension)) {
                  return <Image alt={`${index}`} src={image} />
                } else {
                  return  <video controls autoPlay={false} muted>
                            <source src={image} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                }
              })}
            </div>
            {/* https://github.com/neptunian/react-photo-gallery and https://benhowell.github.io/react-grid-gallery */}
          </div>
        }
        <div className='section'>
          <div className='section-title'>Description</div>
          <div style={{marginTop: 16}} className='artwork-story'>{item.introduction}</div>
        </div>
        <div className='section'>
          <div className='section-title'>Story</div>
          <div style={{marginTop: 16}} className='artwork-story'>{item.story}</div>
        </div>
      </Modal>
    </div>
  )
}

export default Artwork