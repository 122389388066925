import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { Provider } from 'react-redux';
// import { store } from './state/store';

import Snowfall from 'react-snowfall'; // https://github.com/cahilfoley/react-snowfall
import snowImg from './assets/images/1-about/snow.png'; 

const snowflake1 = document.createElement('img')
snowflake1.src = snowImg

ReactDOM.render(
  <React.StrictMode>
    {/* <div style={{ height: 400, width: 400, background: '#282c34', position: 'relative' }}> */}
      {/* <Snowfall 
        // The color of the snowflake, can be any valid CSS color.
        color="#e9ecef"
        // Applied to the canvas element.
        style={{ background: '#fff' }}
        radius={[8, 18]}
        // Controls the number of snowflakes that are created (defaults to 150).
        snowflakeCount={200}
        speed={[0.5, 1]}
        wind={[-0.5, 0.5]}
        images={
          [snowflake1]
        }
      /> */}
    {/* </div>, */}
    {/* <Provider store={store}> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </Provider>, */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();