import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';

import IntroductionSection from './IntroductionSection';
import DataArtSection from './DataArtSection';
import ResearchAndNewsSection from './ResearchAndNewsSection';
import ExperienceAndContactSection from './ExperienceAndContactSection';
import LifePage from './LifePage';

interface HomePageProps {
  textContent: any;
}

const HomePage: React.FC<HomePageProps> = (props) => {
  const { textContent } = props;
  return (
    <>
      <IntroductionSection textContent={textContent} />
      <DataArtSection textContent={textContent} />
      <ResearchAndNewsSection />
      <ExperienceAndContactSection />
      <LifePage />
     </>
  )
};

export default HomePage;