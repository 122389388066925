import React, { useState } from 'react';
import { ArrowRight } from 'lucide-react';
import './index.scss';

import ArtworkCard from './ArtworkCard';
import ArtistGallery from './ArtistGallery';
// import ArcDiagram from '../ArcDiagram';

import { textContent } from '../../types/data.global';
import { Modal } from 'antd';

import rolesImage from '../../assets/images/1-about/roles.png';


const AirbusRedesign: React.FC = () => {


  return (
    <div className='container'>
      {/* <ArcDiagram /> */}

      {/* <div className='header'>
        <div>
          <p>REDESIGN</p>
          <p>UX/UI</p>
        </div>
        <p>AIRBUS.COM</p>
        <div>
          <p>UPROCK</p>
          <p>2023</p>
        </div>
      </div> */}

      <h1 className='logo'>DATA-DRIVEN ART</h1>

      <h2 className='sectionTitle'>INTRODUCTION</h2>
  	  <div className='introduction'>
        <div className='introduction-text'>
      	  {/* "The question of whether this is art or science—or physics or network science—makes absolutely no sense. They are all part of the same journey of discovery." */}

      	  Human society is complex, with many issues that resist immediate change. 
      	  While much of my research leverages data to drive understanding and decision-making, I am deeply interested in scenarios where the focus is not on altering outcomes but on raising awareness and humanistic concerns. In this context, data serves to foster reflection rather than action. When our autdience expands from scientists and decision-makers to the broader public, data-driven art becomes a powerful medium to uncover the ``grey areas'' of society, evoke emotions, and spark public dialogue. 
      	  {/* While my research utilizes data to enable understanding and decision-making, I am equally committed to raising awareness of humanistic concerns.  */}
      	  
      	  {/* Compared to purely humanistic methods, I believe data-driven expressions provide a rational and convincing foundation for presenting these phenomena.  */}
      	  
      	  {/* When audiences expand to the broader public, data-driven art becomes a powerful medium to spark public dialogue.  */}
        </div>
  	  </div>

      <div className='second-section-div'>
        <div className='featured-news-div'>
          <h2 className='sectionTitle'>FEATURED NEWS</h2>
          <div className='featuredNews'>
            {/* <img src="/api/placeholder/400/300" alt="Planet" style={{ width: '100%', height: 'auto' }} /> */}
            <div className='newsGrid'>
              {[
                { category: 'PANEL', title: 'I\'m organizing a VISAP 2024 panel "What Do Visualization Art Projects Bring to the VIS Community?" Welcome to join us!', excerpt: 'Wednesday, Oct 16th, 2024 @07:30–08:45am (CST), Online.' },
                { category: 'PANEL', title: 'I interviewed Amy Cesal, one of the co-founders from Data Visualization Society (DVS), and Daria Koshkina, a data visualization research specialist from The BarabasiLab, as our special guests for our VISAP 2024 panel "What Do Visualization Art Projects Bring to the VIS Community?"', excerpt: 'Sept. 2024, Online' },
                { category: 'EXIHIBITION', title: 'We are now holding an exihibition "ALTERING NATURE: EXPLORING LIFE IN COMPUTATIONAL ART" starting from the late Oct. 2024', excerpt: 'Ping Yuan Andkinmay W Tang Gallery, HKUST, Hong Kong', large: true }
              ].map((news, index) => (
                <div key={index} className={`newsCard ${news.large ? 'newsCardLarge' : ''}`}>
                  <p className='category'>{news.category}</p>
                  <p className='title'>{news.title}</p>
                  <p className='excerpt'>{news.excerpt}</p>
                  <button className='readMore'>
                    READ MORE <ArrowRight size={16} style={{ marginLeft: '4px' }} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='roles-div'>
          <h2 className='sectionTitle'>MY ROLES</h2>
          <img className='roles-image' src={rolesImage} alt='roles' />
        </div>
      </div>




      <h2 className='sectionTitle'>PHILOSOPHICAL REFLECTION</h2>
      <div className="content-card">
        {textContent.dataArtPage.filter((artwork: any) => artwork.classification == 'PHILOSOPHICAL REFLECTION').map((artworkData: any, textIdx: number) =>
          <ArtworkCard key={artworkData.title} data={artworkData} textIdx={textIdx}/>
        )}
      </div>
      
      <h2 className='sectionTitle'>PROTEST AND CRITIQUE</h2>
      <div className="content-card">
        {textContent.dataArtPage.filter((artwork: any) => artwork.classification == 'PROTEST AND CRITIQUE').map((artworkData: any, textIdx: number) =>
          <ArtworkCard key={artworkData.title} data={artworkData} textIdx={textIdx} />
        )}
      </div>


      <h2 className='sectionTitle'>HUMANITY APPRECIATION</h2>
      <div className="content-card">
        {textContent.dataArtPage.filter((artwork: any) => artwork.classification == 'HUMANITY APPRECIATION').map((artworkData: any, textIdx: number) =>
          <ArtworkCard key={artworkData.title} data={artworkData} textIdx={textIdx} />
        )}
      </div>

	  

      <h2 className='sectionTitle'>ARTIST COLLABORATOR</h2>
  	  <div className='aboutUs'>
  	  Collaborating with artists and designers, I have explored and created artworks exhibited globally and featured in top venues like Siggraph Art, IEEE VISAP, ISEA, and The Lumen Prize. 
  	  </div>
      <div className='aboutUs'>
        {/* ArtistGallery component is not defined, so we'll comment it out for now */}
        <ArtistGallery />

        {/* Uncomment and modify this section if needed
        {[
          'What we do',
          'Our worldwide presence',
          'Our products and services'
        ].map((text, index) => (
          <div key={index}>
            <p className='number'>{index + 1}</p>
            <p>{text}</p>
            <button className='discoverMore'>
              DISCOVER MORE <ArrowRight size={16} style={{ marginLeft: '4px' }} />
            </button>
          </div>
        ))} */}
      </div>
      <Modal
    title="Exhibition"
    open={false}
    onCancel={() => {}}
    footer={null}
  >
      <p>Exhibition</p>
    </Modal>
    
    </div>



  );
};

export default AirbusRedesign;
