import React, { useEffect, useRef } from 'react';
import './index.scss';


import yifangImg from '../../../assets/images/3-data-art/artist/yifang.jpg';
import hanluImg from '../../../assets/images/3-data-art/artist/hanlu.jpg'; 
import ziweiImg from '../../../assets/images/3-data-art/artist/ziwei.jpg'; 
import junxiuImg from '../../../assets/images/3-data-art/artist/junxiu.jpg'; 
import xinhuanImg from '../../../assets/images/3-data-art/artist/xinhuan.jpg'; 
import jiayiImg from '../../../assets/images/3-data-art/artist/jiayi.jpg';
import xiaofuImg from '../../../assets/images/3-data-art/artist/xiaofu.jpg';
import danluImg from '../../../assets/images/3-data-art/artist/danlu.jpg';
import shengruiImg from '../../../assets/images/3-data-art/artist/shengrui.jpg';
import guangdaImg from '../../../assets/images/3-data-art/artist/guangda.jpg';
import huaminImg from '../../../assets/images/3-data-art/artist/huamin.jpg';
import kangImg from '../../../assets/images/3-data-art/artist/kang.jpg';


interface Artist {
  name: string;
  specialty: string;
//   description: string;
  imageUrl: string;
  homepageUrl: string;
}

const artists: Artist[] = [
  {
    name: 'YIFANG WANG',
    specialty: 'Visual Analytics, Artificial Intelligence, Computational Social Science, Data-Driven Art',
    // description: "Hector's incredible talent, attention to detail, and precise line work, combined with an incredibly positive attitude, make him one of the shop's most sought-after artists.",
    imageUrl: yifangImg,
	homepageUrl: 'https://wangyifang.top/',
  },
  {
    name: 'JUNXIU TANG',
    specialty: 'Visual Analytics, Information Visualization, Digital Media Art and Technology',
    // description: 'Natashia says, "Each person I work with I see as a unique canvas with the intent to be adorned. Nothing truly compares to the feeling of giving my clients a meaningful and beautiful tattoo for them to cherish on their life\'s journey."',
    imageUrl: junxiuImg,
	homepageUrl: 'https://ahugh19.github.io/',
  },
  {
    name: 'XINHUAN SHU',
    specialty: 'Data Visualization, Data-driven Storytelling, Creativity Support Tool, Human-AI Interaction',
    // description: 'Rob met Hector while they traded off window shifts at another shop. They only put the best artists in the window, so when Bang Bang opened his own place, Rob was one of the first hires.',
    imageUrl: xinhuanImg,
	homepageUrl: 'https://shuxinhuan.github.io/',
  },
  {
    name: 'ZIWEI WU',
    specialty: 'Computational Arts, Bio Arts, Computational Aesthetics, Biology Aesthetics',
    // description: 'Kevin says, "Being surrounded by so many amazing artists motivates me to learn and expand my range. I\'m always pushing my own limits."',
    imageUrl: ziweiImg,
	homepageUrl: 'https://cma.hkust-gz.edu.cn/research-students/ziwei-wu-3/',
  },
  {
    name: 'HANLU MA',
    specialty: 'Media Art, Data Storytelling',
    // description: 'Kevin says, "Being surrounded by so many amazing artists motivates me to learn and expand my range. I\'m always pushing my own limits."',
    imageUrl: hanluImg,
	homepageUrl: 'https://www.behance.net/9129259349cda?locale=en_US',
  },
  {
    name: 'XIAOFU JIN',
    specialty: 'Human-Computer Interaction, Artificial Intelligence',
    // description: 'Kevin says, "Being surrounded by so many amazing artists motivates me to learn and expand my range. I\'m always pushing my own limits."',
    imageUrl: xiaofuImg,
	homepageUrl: 'https://xiaofu-jin.github.io/',
  },
  {
    name: 'DANLU FEI',
    specialty: 'Indie Game Development, Level Design, Visual Design, Narrative Design',
    // description: 'Kevin says, "Being surrounded by so many amazing artists motivates me to learn and expand my range. I\'m always pushing my own limits."',
    imageUrl: danluImg,
	homepageUrl: 'https://cma.hkust-gz.edu.cn/research-students/danlu-fei-6/',
  },
  {
    name: 'GUANGDA ZHU',
    specialty: '3D Animator',
    // description: 'Kevin says, "Being surrounded by so many amazing artists motivates me to learn and expand my range. I\'m always pushing my own limits."',
    imageUrl: guangdaImg,
	homepageUrl: '#',
  },
  {
    name: 'JIAYI ZHOU',
    specialty: 'Data Visualization, Human-Computer Interaction, Human-AI Collaboration, Narrative Visualization',
    // description: 'Kevin says, "Being surrounded by so many amazing artists motivates me to learn and expand my range. I\'m always pushing my own limits."',
    imageUrl: jiayiImg,
	homepageUrl: 'https://jiayzhou.github.io/',
  },
  {
    name: 'RUI SHENG',
    specialty: 'Data Visualization, Data-driven Decision-making, Social Computing',
    // description: 'Kevin says, "Being surrounded by so many amazing artists motivates me to learn and expand my range. I\'m always pushing my own limits."',
    imageUrl: shengruiImg,
	homepageUrl: 'https://dylansheng.github.io/',
  },

  
  {
    name: 'PROF. KANG ZHANG',
    specialty: 'Generative Art, Computational Aesthetics',
    // description: 'Kevin says, "Being surrounded by so many amazing artists motivates me to learn and expand my range. I\'m always pushing my own limits."',
    imageUrl: kangImg,
	homepageUrl: 'https://cma.hkust-gz.edu.cn/faculty-regular/kang-zhang/',
  },
  {
    name: 'PROF. HUAMIN QU',
    specialty: 'Data Visualization, Information Visualization, Computational Media and Arts',
    // description: 'Kevin says, "Being surrounded by so many amazing artists motivates me to learn and expand my range. I\'m always pushing my own limits."',
    imageUrl: huaminImg,
	homepageUrl: 'http://huamin.org/',
  },
];

const ArtistGallery: React.FC = () => {
  const fadeInElements = useRef<NodeListOf<Element> | null>(null);

  useEffect(() => {
    fadeInElements.current = document.querySelectorAll('.fade-in');

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target); // Stop observing once the element is visible
          }
        });
      },
      { threshold: 0.1 }
    );

    fadeInElements.current.forEach((el) => observer.observe(el));

    return () => {
      fadeInElements.current?.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <div className="artist-gallery">
        {artists.map((artist) => (
          <div key={artist.name} className="artist-card fade-in">
            <img src={artist.imageUrl} alt={artist.name} className="artist-card__image" />
            <h2 className="artist-card__name">{artist.name}</h2>
            <p className="artist-card__specialty">{artist.specialty}</p>
            {/* <p className="artist-card__description">{artist.description}</p> */}
            <p className="artist-card__view-gallery">
				<a href={artist.homepageUrl} target="_blank" rel="noopener noreferrer">VIEW HOMEPAGE</a>
			</p>
          </div>
        ))}
    </div>
  );
};

export default ArtistGallery;
