// SpaceNewsDashboard.tsx
// import React from 'react';
import React, { useState, useEffect, useRef } from 'react';
import { ArrowDownOutlined } from '@ant-design/icons';
import './index.scss';
import { Modal, Image } from 'antd';
import {
	FilePdfFilled,
	FilePptFilled,
	FileTextFilled,
	VideoCameraFilled,
	LayoutFilled,
	CloseOutlined, 
  } from '@ant-design/icons';
import _ from 'lodash';


interface ArtworkCardProps {
	data: any;
	textIdx: number;
}

const ArtworkCard: React.FC<ArtworkCardProps> = ({ data, textIdx }) => {


//   console.log('data', data);

  // find video
  const videoItem = data.suppMaterialList.find((item: any) => item.name === 'Demo Video')


  const fadeInElements = useRef<NodeListOf<Element> | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    console.log('showModal');
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fadeInElements.current = document.querySelectorAll('.fade-in');

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target); // Stop observing once the element is visible
          }
        });
      },
      { threshold: 0.1 }
    );

    fadeInElements.current.forEach((el) => observer.observe(el));

    return () => {
      fadeInElements.current?.forEach((el) => observer.unobserve(el));
    };
  }, []);


  const authorElementList: JSX.Element[] = []
  _.forEach(data.authorList, (author, idx: number) => {
    if (author === 'Yifang Wang') {
      authorElementList.push(<span className='author-highlight' key={`${textIdx}-${idx}-${author}-highlight`} id={`${textIdx}-${idx}-${author}`}>{author}</span>)
    } else {
      authorElementList.push(<span key={`${textIdx}-${idx}-${author}-common`} id={`${textIdx}-${idx}-${author}`}>{author}</span>)
    }
    if (idx < data.authorList.length - 1) {
      authorElementList.push(<span key={`${textIdx}-${idx}-${author}-comma`} id={`${textIdx}-${idx}-${author}`}>, </span>)
    }
  })

  return (
    <div className="artwork-card-div">
		<div className="teaser fade-in">
			<img src={data.teaser} alt="teaser" />
		</div>
		<div className='left-parent unified'>
			<div className="grid secondary-image fade-in">
				<div className="left">
					<img src={data.detailPageData.secondaryImageInfo.image} alt="secondaryImageInfo" />
				</div>
				<div className="right">
			  		<div className="content fade-in">
					    <div className="header">
					      <span>DESIGN</span>
					      <span>IDEA</span>
				    	</div>
					    <h2 className="title">{data.detailPageData.secondaryImageInfo.title}</h2>
					    <div className='background-idea'>
					      {data.detailPageData.secondaryImageInfo.description}
					    </div>
					    <div className="scroll-hint">
					      <span style={{ color: '#9E62FFff', fontStyle: 'italic' }}>Scroll down</span>
					      <ArrowDownOutlined style={{ color: '#9E62FFff' }} />
					    </div>
					    {/* <button className="read-more">READ MORE ▶</button> */}
					</div>
				</div>
		    </div>
		    
		    <div className="grid empty-space fade-in">
				<div className="left empty">
					<div className="half"></div>
				</div>
				<div className="right empty">
					<div className="diagonal-line"></div>
				</div>
		    </div>
		    
		    <div className="grid award fade-in">
		      
			  	<div className="left">
					<img src={data.detailPageData.awardImageInfo.image} alt="secondaryImageInfo" />
				</div>
				<div className="right">
					<div className="content fade-in">
						<div className="header">
							<span>EXIHIBITION</span>
							<span>AWARD</span>
						</div>
						<div className="award-logo-list">
							{data.detailPageData.awardImageInfo.awardLogoList.map((image: string, index: number) => (
							<img key={index} src={image} alt="Award" />
							))}
						</div>
					</div>
				</div>
		    </div>
		</div>
		<div className='right-parent unified'>
		    <div className="grid exihibition fade-in">
				{/* add demo video */}
				{videoItem ? (
					<div className="video-container">
						<video controls autoPlay={true} muted style={{width: '100%', height: '100%', objectFit: 'cover'}}>
							<source src={videoItem.fileLink} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</div>
				) : (
					<div className="image-container">
						<img src={data.detailPageData.exihibitionImageInfo.image} alt="exhibition" style={{width: '100%', height: '100%', objectFit: 'cover'}} />
					</div>
				)}
		      {/* <img src={data.detailPageData.exihibitionImageInfo.image} alt="exihibition-img" /> */}
		      <div className="content fade-in">
		          <div className="header">
		            <span>EXIHIBITION</span>
		            <span>{data.detailPageData.exihibitionImageInfo.time}</span>
		          </div>
				  <div className='exihibition-content' style={{height: data.detailPageData.exihibitionImageInfo.title === 'Hidden Incidents of Food Safety' ? 'calc(100% - 8rem)' : 'calc(100% - 4rem)'}}>
			          <h2 className="title">{data.detailPageData.exihibitionImageInfo.title}</h2>
					  <div className='exhibition-description'>
					  	{data.detailPageData.exihibitionImageInfo.description}
					  </div>
				  </div>
				  <div className="scroll-hint">
				      {/* <span style={{ color: '#9E62FFff', fontStyle: 'italic' }}>Scroll down</span>
				      <ArrowDownOutlined style={{ color: '#9E62FFff' }} /> */}
					  <button className="read-more" onClick={showModal}>READ MORE ▶</button>	
				  </div>
				          
		      </div>
		    </div>
		</div>


		<Modal
	        key={data.title}
	        className="artwork-modal"
	        // title={item.titleShort}
	        open={isModalOpen}
	        onCancel={handleCancel}
	        closeIcon={<CloseOutlined onClick={handleCancel} />}
	        footer={null}  // Remove default footer buttons
	        width={'80%'}
	        centered
	        // style={{ top: 20 }}  // Adjust top margin if needed
	      >
	        <div className='section' style={{margin: '0 0'}}>
	          {videoItem ? (
	            <video controls autoPlay={true} muted style={{width: '100%', height: '420px', objectFit: 'cover'}}>
	              <source src={videoItem.fileLink} type="video/mp4" />
	              Your browser does not support the video tag.
	            </video>
	          ) : (
	            <figure style={{width: '100%', height: '420px', margin: 0}}>
	              <img src={data.detailPageData.exihibitionImageInfo.image} alt="exhibition" style={{width: '100%', height: '100%', objectFit: 'cover'}} />
	            </figure>
	          )}
	        </div>
	        {/* <div className='section' style={{margin: '0 0', position: 'relative'}}>
	          {
	            (videoItem) && 
	            <video controls autoPlay={true} muted style={{position: 'absolute', width: '100%', height: '100%', objectFit: 'cover'}}>
	              <source src={videoItem.fileLink} type="video/mp4" />
	              Your browser does not support the video tag.
	            </video>
	          }
	          <div className='artwork-title' style={{position: 'relative', zIndex: 1}}>
	            <div>{data.title}</div>
	          </div>
	        </div> */}
	        <div className='section'>
	          <div className='section-title'>Publication</div>
	          <div className='title'>{data.title}</div>
	          <div className='author'>{authorElementList}</div>
	          <div className='supp'>
	            [{data.suppMaterialList.map((suppItem: any, idx: number) => {
	                let hrefElement = <></>
	                if (idx === 0) {
	                  if (data.suppMaterialList.length > 1) {
	                    hrefElement = <a href={suppItem.fileLink}>{suppItem.name} |</a>
	                  } else {
	                    hrefElement = <a href={suppItem.fileLink}>{suppItem.name}</a>
	                  }
	                }
	                else if (idx < data.suppMaterialList.length - 1) {
	                  hrefElement = <a href={suppItem.fileLink}> {suppItem.name} |</a>
	                } else {
	                  hrefElement = <a href={suppItem.fileLink}> {suppItem.name}</a>
	                }

	                let iconElement = <></>
	                if (suppItem.name === 'PDF') {
	                  iconElement = <FilePdfFilled />
	                  // iconElement = <img src={pdfIconUrl} alt='pdf-icon'/>
	                } else if (suppItem.name === 'Demo Video' || suppItem.name === 'Background Video' || suppItem.name === 'Case Video') {
	                  iconElement = <VideoCameraFilled />
	                  // iconElement = <img src={videoIconUrl} alt='video-icon'/>
	                } else if (suppItem.name === 'Slides') {
	                  iconElement = <FilePptFilled />
	                  // iconElement = <img src={pptIconUrl} alt='ppt-icon'/>
	                } else if (suppItem.name === 'Project Page' || suppItem.name === 'Official Website') {
	                  iconElement = <LayoutFilled />
	                  // iconElement = <img src={websiteIconUrl} alt='website-icon'/>
	                } else if (suppItem.name === 'Supp Material' || suppItem.name === 'ICSSI2023 Poster') {
	                  iconElement = <FileTextFilled />
	                  // iconElement = <img src={websiteIconUrl} alt='website-icon'/>
	                }

	                return (<div className='supp-unit-div'>
	                  {iconElement}
	                  {hrefElement}
	                </div>)
	              })}]
	          </div>
	        </div>
	        {
	          (data.award.length > 0) && 
	          <div className='section'>
	            <div className='section-title'>Exhibition & Prize</div>
	            <div className='award' style={{marginTop: 8}}>
	              { (data.award.map((item: string) => 
	                <div className='content'>
	                  {item}
	                </div>
	              ))}
	            </div>
	          </div>
	        }
	        { (data.mediaCoverage.length > 0) && 
	          <div className='section'>
	            <div className='section-title'>Media Coverage</div>
	            { (data.mediaCoverage.map((item: { name: string, title: string, fileLink: string }) => 
	            <div className='media-unit'>
	                <div className='media-unit-section media-title'>
	                  <div className='field-wrapper'>
	                    <div className='field'>Title</div>
	                  </div>
	                  <div className='content'><a href={item.fileLink}>{item.title}</a></div>
	                </div>
	                <div className='media-unit-section media-name'>
	                  <div className='field-wrapper'>
	                    <div className='field'>Media</div>
	                  </div>
	                  <div className='content'>{item.name}</div>
	                </div>
	            </div>))}
	          </div>
	        }
	        {
	          (data.imageList.length > 0) && 
	          <div className='section'>
	            <div className='section-title'>Gallery</div>
	            <div className='gallery' style={{marginTop: 8}}>
	              {data.imageList.map((image: string, index: number) => { 
	                const fileExtension = image.slice(image.length - 3, image.length).toLowerCase();
	                if (!['mp4'].includes(fileExtension)) {
	                  return <Image alt={`${index}`} src={image} />
	                } else {
	                  return  <video controls autoPlay={false} muted>
	                            <source src={image} type="video/mp4" />
	                            Your browser does not support the video tag.
	                          </video>
	                }
	              })}
	            </div>
	            {/* https://github.com/neptunian/react-photo-gallery and https://benhowell.github.io/react-grid-gallery */}
	          </div>
	        }
	        <div className='section'>
	          <div className='section-title'>Description</div>
	          <div style={{marginTop: 16}} className='artwork-story'>{data.introduction}</div>
	        </div>
	        <div className='section'>
	          <div className='section-title'>Story</div>
	          <div style={{marginTop: 16}} className='artwork-story'>{data.story}</div>
	        </div>
	    </Modal>
  </div>
  );
};

export default ArtworkCard;
